import { userAxios } from '../index'

export const getAdminData = async data => {
  try {
    return await userAxios.post(`insurance/admin-data`, data).then(res => res.data)
  } catch (error) {
    console.log(error)
  }
}

export const compareFacilities = async data => {
  try {
    return await userAxios
      .post(`insurance/admin-data/compare`, data)
      .then(res => res.data)
  } catch (error) {
    console.log(error)
  }
}

export const getAdminDataPaginated = async (page, data) => {
  try {
    return await userAxios
      .post(`insurance/admin-data?page=${page}`, data)
      .then(res => res.data)
  } catch (error) {
    console.log(error)
  }
}

export const getDetailsOfficeVisit = async id => {
  try {
    return await userAxios.get(`insurance/officeVisits/${id}`).then(res => res.data)
  } catch (error) {
    console.log(error)
  }
}

export default {
  getAdminData,
  getAdminDataPaginated,
  getDetailsOfficeVisit,
  compareFacilities,
}
