<template>
  <div>
    <campaignFilter v-if="$can('filter', 'laboratory-categorys')" :loading="loading" @searchFilter="searchFilter"
      @filterPage="filterPage" />
    <b-card>
      <TitleTable titleTable="Campaign" iconTable="fas fa-disease" />
      <div class="m-1">
        <campaignEdit v-if="editCampaignBool" :is-edit-laboratory-category-sidebar-active.sync="editCampaignBool"
          :itemEdit="itemEdit" @editcampaignSuccess="editcampaignSuccess" />
        <campaignAdd :isAddNewCampaignSidebarActive.sync="addCampaignBool" @createCampaign="createCampaign" />
        <Cupons ref="tablarda" @paginated="cuponsPage = $event" :seeCupons.sync="seeIncomes"
          @sendDataTable="dataTable = $event" />
        <seeCupons :campaignName="campaignName" :cupons="cupons" :isCuponsModalActive.sync="seeCuponsBool"
          @closeModalCupons="closeModalCupons" />
        <!-- Table Top -->
        <b-row>
          <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
            <treeselect v-model="perPage" :options="perPageOptions" :clearable="true" class="w-25" />
          </b-col>
          <!-- {{ $t("Search") }} -->
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <GoBack class="mr-2" />
              <b-button class="mr-2" variant="info" @click="seeIncomes = true">
                <span class="text-nowrap">See incomes</span>
              </b-button>
              <b-button v-if="$can('store', 'laboratory-categorys')" variant="primary" @click="addCampaignBool = true"
                :disabled="loading">
                <span v-if="!loading" class="text-nowrap">Add campaign</span>
                <span v-else>
                  <SpinnerLoading />
                </span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table :items="items" :fields="fields" hover bordered responsive primary-key="id" class="position-relative"
        show-empty empty-text="No matching records found">
        <template #cell(facilities)="data">
          <ul class="list-unstyled my-2 facility">
            <li v-for="(facility, i) in data.item.facilities" :key="i">
              <b-badge variant="primary" class="mb-1"><span class="text-white">{{ facility.name }}</span></b-badge>
            </li>
          </ul>
        </template>
        <template #cell(quantity)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize">{{ getCuponsActives(data.item.campaigns_cupons) }} / {{
              data.item.quantity }}</span>
          </div>
        </template>
        <template #cell(status)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize">{{
              data.item.status ? $t("Active") : $t("Inactive")
            }}</span>
          </div>
        </template>
        <template #cell(actions)="data">
          <b-button variant="outline-primary" class="btn-icon btn-circle border-0" v-b-tooltip.hover.top="'See Landing Page'"
            @click="seeLanding(data.item)">
            <i class="fas fa-file icon-nm"></i>
          </b-button>

          <b-button v-if="$can('update', 'laboratory-categorys')" variant="outline-primary"
            class="btn-icon btn-circle border-0" v-b-tooltip.hover.top="'See cupons'" @click="seeCupons(data.item)">
            <i class="fas fa-eye icon-nm"></i>
          </b-button>

          <b-button v-if="$can('update', 'laboratory-categorys')" variant="outline-primary"
            class="btn-icon btn-circle border-0" v-b-tooltip.hover.top="'Edit'" @click="editcampaign(data.item)">
            <i class="fas fa-pen icon-nm"></i>
          </b-button>

          <b-button v-if="$can('destroy', 'laboratory-categorys')" variant="outline-danger"
            class="btn-icon btn-circle border-0" v-b-tooltip.hover.top="'Delete'" @click="deletecampaign(data.item)">
            <i class="fas fa-trash-alt icon-nm"></i>
          </b-button>
        </template>
      </b-table>
      <PaginationTable :dataMeta="dataMeta" :totalUsers="totalUsers" :perPage="perPage" :currentPage="currentPage"
        @page-changed="pageChanged" />
    </b-card>
    <ToastNotification ref="toast" />
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BTable,
  BBadge,
  BAvatar,
  BButton,
  VBTooltip,
} from "bootstrap-vue";
import axios from "@/core/services/apiInsurance/admin/consults/campaigns";
import axiosAD from "@/core/services/apiInsurance/admin/adminData";

import ToastNotification from "@/components/ToastNotification";
import SpinnerLoading from "@/components/SpinnerLoading";
import PaginationTable from "@/components/PaginationTable";
import TitleTable from "@/components/TitleTable";
import GoBack from "@/components/ButtonBack";
import Swal from "sweetalert2";
import filterData from "@/view/pages/adminData/filterData.vue";

import campaignEdit from "./editCampaign";
import Cupons from "./cupons";
import campaignAdd from "./addCampaign";
import seeCupons from "./seeCupons";
import campaignFilter from "./filter";
import { ref } from "@vue/composition-api";
export default {
  components: {
    BCard,
    BRow,
    BCol,
    BTable,
    BBadge,
    BAvatar,
    BButton,
    campaignEdit,
    campaignAdd,
    campaignFilter,
    ToastNotification,
    SpinnerLoading,
    PaginationTable,
    TitleTable,
    GoBack,
    seeCupons,
    Cupons,
    filterData
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      fields: [
        "id",
        { key: "name", label: "Name", sortable: true },
        { key: "description", label: "Description", sortable: true },
        { key: "prefix", label: "Prefix", sortable: true },
        { key: "quantity", label: "Quantity" },
        { key: "cupons_per_patient", label: "Cupons per Patient" },
        { key: "end_date", label: "End Date" },
        { key: "type", label: "Type" },
        { key: "value", label: "Value" },
        { key: "facilities", label: "Facilities" },
        { key: "message", label: "Message" },
        { key: "actions", label: "Actions" },
      ],
      items: null,
      loading: false,
      editCampaignBool: false,
      addCampaignBool: false,
      itemEdit: null,
      dataMeta: { from: 1, to: 1, of: 1 },
      totalUsers: 0,
      perPage: 10,
      campaignName: '',
      cupons: [],
      seeCuponsBool: false,
      seeIncomes: false,
      currentPage: {
        page: 1,
      },
      perPageOptions: [10, 25, 50, 100].map((i) => ({
        id: i,
        label: `Show ${i} Entries`,
      })),
      filtro: false,
      campaignFilter: null,
    };
  },
  mounted() {
    this.getCampaign();
  },
  watch: {
    perPage() {
      if (!this.filtro) {
        this.getCampaign();
      } else {
        this.searchFilter(this.campaignFilter);
      }
    },
  },
  methods: {
    seeLanding(campaign) {
      const newUrl = (window.location.href).split("://")[1].split("/")[0]
      window.open(`https://${newUrl}/get-cupon/${campaign.name}`, '_blank')
    },
    getCuponsActives(cupons) {
      return cupons.filter(cupon => !cupon.status).length
    },
    getCampaign() {
      axios.campaignList(this.perPage).then(({ data: { data, current_page, total, per_page, ...res } }) => {
        this.items = data;
        this.totalUsers = total;
        this.dataMeta.of = total;
        this.dataMeta.from = res.from;
        this.dataMeta.to = res.to;
        this.currentPage.page = current_page;
      });
    },
    seeCupons(item) {
      this.seeCuponsBool = true;
      this.cupons = item.campaigns_cupons
      this.campaignName = item.name
    },
    editcampaign(item) {
      this.editCampaignBool = true;
      this.itemEdit = ref(JSON.parse(JSON.stringify(item)));
    },
    closeModalCupons() {
      this.seeCuponsBool = false;
    },
    editcampaignSuccess(value) {
      if (value) {
        this.$refs.toast.success("Campaign updated successfully");
      } else {
        this.$refs.toast.error("Error updating campaign");
      }
      this.editCampaignBool = false;
      this.pageChanged(this.currentPage.page);
    },
    createCampaign({ status }) {
      if (status) {
        this.$refs.toast.success("campaign added successfully");
      } else {
        this.$refs.toast.danger("campaign not added");
      }
      this.addCampaignBool = false;
      this.pageChanged(this.currentPage.page);
    },
    deletecampaign(item) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this",
        icon: "error",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          axios.campaignDelete(item.id).then((response) => {
            this.$refs.toast.success("campaign has been deleted successfully");
            this.pageChanged(this.currentPage.page);
          });
        }
      });
    },
    pageChanged(page) {
      if (!this.filtro) {
        axios.campaignPagination(this.perPage, page).then(({ data: { data, total, current_page, ...res } }) => {
          this.items = data;
          this.totalUsers = total;
          this.dataMeta.of = total;
          this.dataMeta.from = res.from;
          this.dataMeta.to = res.to;
          this.currentPage.page = current_page;
        });
      } else {
        axios.campaignFilterPagination(this.perPage, page, this.campaignFilter).then(({ data: { data, total, current_page, ...res } }) => {
          this.items = data;
          this.totalUsers = total;
          this.dataMeta.of = total;
          this.dataMeta.from = res.from;
          this.dataMeta.to = res.to;
          this.currentPage.page = current_page;
        });
      }
    },
    searchFilter(value) {
      if (this.filtro) {
        this.loading = true;
        this.campaignFilter = value;
        axios.campaignFilter(this.perPage, value).then(({ data: { data, total, current_page, ...res } }) => {
          this.loading = false;
          this.items = data;
          this.totalUsers = total;
          this.dataMeta.of = total;
          this.dataMeta.from = res.from;
          this.dataMeta.to = res.to;
          this.currentPage.page = current_page;
        }).catch(() => {
          this.loading = false;
        });
      } else {
        this.getCampaign();
      }
    },
    filterPage(value) {
      this.filtro = value;
    },
  },
};
</script>

<style></style>
